/* Set the colors for each value */
  .custom-rating.red {
    color: red;
  }
  
  .custom-rating.orange {
    color: orange;
  }
  
  .custom-rating.yellow {
    color: yellow;
  }
  
  .custom-rating.lime {
    color: lime;
  }
  
  .custom-rating.green {
    color: green;
  }
  
  /* Change color on hover for each value */
  .custom-rating.red:hover,
  .custom-rating.red.ant-rate-star-full {
    color: red;
  }
  
  .custom-rating.orange:hover,
  .custom-rating.orange.ant-rate-star-full {
    color: orange;
  }
  
  .custom-rating.yellow:hover,
  .custom-rating.yellow.ant-rate-star-full {
    color: yellow;
  }
  
  .custom-rating.lemon:hover,
  .custom-rating.lemon.ant-rate-star-full {
    color: lime;
  }
  
  .custom-rating.green:hover,
  .custom-rating.green.ant-rate-star-full {
    color: green;
  }
  .small-rating {
    font-size: 16px; /* Adjust the size as needed */
  }

  .smaller-rating {
    font-size: 8px;
  }
  
  .large-rating {
    font-size: 32px; /* Adjust the size as needed */
  }

  a{
    text-decoration: none;
  }

  
   /* Styles for screens between 768px and 899px */
   @media screen and (max-width: 899px) {
    .Listing {
      flex-direction: column; /* Stack listings vertically */
    }

    .Image {
      width: 100% !important;  /* Make images full-width */
    }

    .Image img {
      width: 100%; /* Make images full-width */
      height: auto;
    }

    /* Adjust other styles as needed for smaller screens */
  }

  /* Styles for screens smaller than 768px (e.g., mobile devices) */
  @media screen and (max-width: 767px) {
    /* Center the listing content on smaller screens */
    .Listing {
      flex-direction: column; /* Stack listings vertically */
      align-items: center; /* Center content horizontally */
    }
    .form-img {
      display: none;
    }

    /* .form{
      width: 300px;
    } */
    /* Adjust other styles for even smaller screens */
  }

  /* Your CSS styles for screens between 600px and 624px wide go here */
  @media screen and (max-width: 599px) {
    .listingCard .Content {
      width: 100% !important;
    }
  }

  /********** Template CSS **********/
:root {
  --primary: #355EFC;
  --secondary: #E93C05;
  --tertiary: #555555;
  --light: #DFE4FD;
  --dark: #011A41;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}

h1,
.h1,
h2,
.h2,
.fw-bold {
  font-weight: 700 !important;
}

h3,
.h3,
h4,
.h4,
.fw-medium {
  font-weight: 600 !important;
}

h5,
.h5,
h6,
.h6,
.fw-semi-bold {
  font-weight: 500 !important;
}


/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity .5s ease-out, visibility 0s linear .5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity .5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}


/*** Button ***/
.btn {
  transition: .5s;
  font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
  color: var(--light);
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}

.feature .feature-box,
.feature .feature-box * {
  transition: .5s !important;
}

.feature .feature-box:hover {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.feature .feature-box:hover * {
  color: #FFFFFF !important;
}


.form{
  position: relative;
}

.left-pan{
  position: absolute;
  right: 17px;
  top: 2px;
  padding: 2px;
  border-left: 1px solid #d1d5db;

}

.form-input {
  height: 55px;
  text-indent: 33px;
  border-radius: 10px;
  padding-right: 50px;
}

.form-input:focus{
  border:none;
}

.center-image .station-logo, .center-image .station-logo img {
  border-radius: 20px;
}

.station-logo{
  max-width: max-content;
}

/* .MuiButton-startIcon11{
  margin-top: 10px;
}; */
/* Optional: Add media queries to adjust the image size on small screens */
@media (max-width: 600px) {
  .center-image .station-logo {
    display: block;
    margin: 0 auto;
    max-width: 100%; /* Change 'max-content' to '100%' to make it responsive */
  }

  .center-image {
    margin: 0 auto;
  }

  /* .station-img {
    max-width: 96px !important; 
    max-height: 120px !important; 
    display: block;
    margin: 0 auto;
  } */

  .center-content {
    /* display: flex; */
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}


.login-btn{
  padding: 13px 20px 12px;
  background-color: #000;
  border-radius: 4px;
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  margin-bottom: 24px;
  width: 100%;
  border-color: #000;
}

.login-btn:hover {
  border: '1px solid #000';
  border-color: #000;
  background-color: 'transparent';
  color: '#000';
}

.modal-confirm {		
  color: #636363;
  width: 325px;
  margin: 30px auto;
}
.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
}
.modal-confirm .modal-header {
  border-bottom: none;   
      position: relative;
}
.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -15px;
}
.modal-confirm .form-control, .modal-confirm .btn {
  min-height: 40px;
  border-radius: 3px; 
}
.modal-confirm .close {
      position: absolute;
  top: -5px;
  right: -5px;
}	
.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
}	
.modal-confirm .icon-box {
  color: #fff;		
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 95px;
  height: 95px;
  border-radius: 50%;
  z-index: 9;
  background: #82ce34;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.modal-confirm .icon-box i {
  font-size: 58px;
  position: relative;
  top: 3px;
}
.modal-confirm.modal-dialog {
  margin-top: 80px;
}
.modal-confirm .btn {
  color: #fff;
  border-radius: 4px;
  background: #82ce34;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  border: none;
}
.modal-confirm .btn:hover, .modal-confirm .btn:focus {
  background: #6fb32b;
  outline: none;
}
.trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

@media (min-width: 769px) {
  .sticky-list-card {
    position: sticky;
    top: 135px; 
  }
  .station-filter-fixed{
    position: sticky;
    top: 175px; 
  }
  .form{
    position: relative;
    width: 400px;
  }
}

.st0{fill:#fff}
.st1{fill:#b5dfea}
.st2{opacity:.55;fill:#90cedd}
.st3{fill:#d7f0f9}.st4{fill:#0582c1}
.st5{fill:#79c9e8}.st6{fill:#ffbf4d}
.st7{fill:#00668e}.st8{fill:#05556d}
.st9{fill:#f98d3d}.st10{fill:#ed701b}
.st11{fill:none}.st12{fill:#efaa3a}
.st13{opacity:.29;fill:#f98d2b}
.st14{fill:#49b4d6}.st15{fill:#ff9f50}
.st16{fill:#f77e2d}
.st17{opacity:.55;fill:url(#SVGID_1_)}



/* 500 Page */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
.main-error-page {
  min-height: 600px;
  margin: 0px auto;
  width: auto;
  max-width: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error-title {
  max-width: 529px; 
  font-family: Roboto;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4b4b62;
  margin-bottom: 16px;
}

.error-subtitle {
  max-width: 568px; 
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #4b4b62;
  margin-bottom: 24px;
}
svg {
  margin-bottom: 16px;
}


.full-torradeira {
   
}
.torradeira {
}

.pao-atras {
  animation: leftright 1s alternate infinite;
  transform-origin: center;
}
.pao-frente {
    animation: leftright 1s 0.3s alternate infinite;
  transform-origin: center;
}

.olho-esq {
  
    animation: sad 2s alternate infinite;
  transform-origin: center;
}

.olho-dir {
  
    animation: sad 2s alternate infinite;
  transform-origin: center;
}
.boca {
    animation: sad 2s alternate infinite;
  transform-origin: center;
}
.raios {
  -webkit-animation: flicker-4 4s linear infinite both;
  animation: flicker-4 4s linear infinite both;
}
.tomada {
  -webkit-animation: vibrate-1 3s linear infinite both;
	        animation: vibrate-1 3s linear infinite both;
}
.fio-500 {
  -webkit-animation: vibrate-1 3s linear infinite both;
	        animation: vibrate-1 3s linear infinite both;
}
.fio{ 
  -webkit-animation: vibrate-1 3s linear infinite both;
	        animation: vibrate-1 3s linear infinite both;
}

@keyframes scales {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 14:58:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-4
 * ----------------------------------------
 */
@-webkit-keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}
@keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:17:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
            transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
            transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
            transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
            transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
            transform: translate(0);
  }
}



/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:42:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes wobble-ver-right {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
            transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(6deg);
            transform: translateY(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(-6deg);
            transform: translateY(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(3.6deg);
            transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(-2.4deg);
            transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(1.2deg);
            transform: translateY(-6px) rotate(1.2deg);
  }
}
 

@keyframes sad {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(10deg)  rotateY(5deg);
  }
}

@keyframes leftright{
  
  0%{
    transform: rotateZ( 0deg)
  }
   100%{
    transform: rotateZ( -15deg)
  }
}